import { useEffect, useRef } from "react";
import SplitText from "../components/SplitText";
import Navbar from "../components/Navbar";
import left from "../assets/imgs/topLeftImg.png";
import rightN from "../assets/imgs/rightNew.png";
import centerN from "../assets/imgs/centerNew.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Helmet } from "react-helmet";
import RedesignedFooter from "../components/RedesignedFooter";
import WhyDoWeExist from "./WhyDoWeExist";
import useResponsiveFontSize from "../utils/useResponsiveFontSize";
import OtherFeatues from "./OtherFeatues";

const RedesignedHome = () => {
  let fontSize = useResponsiveFontSize(1440, 36);

  const missionRef = useRef<HTMLDivElement>(null);
  const visionRef = useRef<HTMLDivElement>(null);
  const triggerRef1 = useRef<HTMLDivElement>(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    console.log("InnerWidth: " + window.innerWidth);
    console.log("Font Size: " + fontSize);
  }, [window.innerWidth]);

  useGSAP(() => {
    gsap.from(missionRef.current, {
      opacity: 0,
      y: -300,
      duration: 1.4,
      scrollTrigger: {
        trigger: triggerRef1.current,
        scroller: "body",
        start: "top 40%",
      },
    });
    gsap.from(visionRef.current, {
      opacity: 0,
      y: 300,
      duration: 1.4,
      scrollTrigger: {
        trigger: triggerRef1.current,
        scroller: "body",
        start: "top 40%",
      },
    });
  });

  return (
    <>
      <Helmet>
        <title>Xampus - AI Enabled Professional Network</title>
        <meta
          name="description"
          content="Unlock your full professional potential with our tips! Discover how AI can transform your job search experience and lead you to outstanding career opportunities. Don’t miss your chance to grow!"
        />
        <meta name="keywords" content="xampus, home page, jobs, career" />
      </Helmet>

      <Navbar styles="" />
      {/* hero section */}
      <div className="relative text-center h-[22rem] md:h-[50rem] lg:h-[50rem]">
        <div className="text-center inline-block mt-[1rem] md:mt-[1rem]">
          <h1 className="lg:text-[5.6rem] text-[2rem] font-bold md:text-[4rem]">
            <SplitText text="XampusAI" />
          </h1>
        </div>

        <div style={{ height: `${useResponsiveFontSize(1440, 117)}px` }}>
          <span className="font-semibold text-[#0A1E67] lg:text-[20px] text-[15px]">
            Connecting You, Your Audience, and <br /> Endless Opportunities
          </span>
        </div>

        {/* TOP 3 IMAGES DIVS */}

        <div className="left-img-div">
          <img
            src={left}
            alt="leftIphoneImg"
            loading="lazy"
            className="left left-img lg:h-[30rem] h-[12rem] -mb-1 md:-mb-0 md:h-[32rem] lg:mr-[1.5rem]"
          />
          {/*  */}
        </div>

        <div className="video-div z-40">
          <img
            src={centerN}
            alt="centerIphoneImg"
            loading="lazy"
            className=" w-[105px]  md:w-80 md:h-[40rem] lg:w-[17.5rem]  lg:h-[34rem] -mb-5 lg:-mb-7"
          />
        </div>

        <div className="right-img-div">
          <img
            src={rightN}
            alt="rightIphoneImg"
            loading="lazy"
            className="right right-img lg:h-[30rem] h-[12rem] -mb-1 md:-mb-0 md:h-[32rem] lg:ml-[1.5rem]"
          />
        </div>
      </div>

      <WhyDoWeExist />

      {/* for 3rd setion your future powered ai */}
      <OtherFeatues />

      <RedesignedFooter />
    </>
  );
};

export default RedesignedHome;
