import bestValuePlan from "../../assets/imgs/bestValuePlan.png";
import starterPlan from "../../assets/imgs/starterPlan.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import Reveal from "../Reveal";

interface Slide5Props {
  isActive: boolean;
}

function Slide5() {
  const slideRef = useRef<HTMLDivElement>(null);
  // useGSAP(() => {
  //   if (isActive) {
  //     gsap.registerPlugin(ScrollTrigger);

  //     gsap.fromTo(
  //       ".font-extrabold",
  //       { y: 100, opacity: 0 },
  //       {
  //         y: 0,
  //         opacity: 1,
  //         duration: 1,
  //         scrollTrigger: {
  //           trigger: ".font-extrabold",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );

  //     gsap.fromTo(
  //       ".xampus",
  //       { x: -100, opacity: 0 },
  //       {
  //         x: 0,
  //         opacity: 1,
  //         duration: 1.9,
  //         scrollTrigger: {
  //           trigger: ".xampus",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );
  //   }
  // }, [isActive]);

  return (
    <div className="bg-[#030a74] lg:h-[70vh] h-[40vh] overflow-hidden relative w-full 2xl:flex justify-center">
      <div className="lg:hidden absolute top-2 flex justify-center items-center w-full">
        <p className="text-[#ffffff] lg:text-[45px] font-extrabold">
          PRODUCT OFFERINGS
        </p>
      </div>

      <div className="lg:flex lg:mt-[4rem] lg:ml-[5rem] lg:gap-10  mt-[4rem]">
        <div className="flex lg:justify-start justify-center items-center lg:gap-10 lg:ml-0 gap-4">
          <div className="lg:w-[318px] lg:h-[395px] w-[170px] h-[210px]">
            <Reveal>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FstarterPlan.png?alt=media&token=ead84610-4ae9-4510-a279-206c950a650f"
                alt="starterPlan"
                className="h-full"
              />
            </Reveal>
          </div>
          <div className="lg:w-[318px] lg:h-[395px] w-[170px] h-[210px]">
            <Reveal>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FbestValuePlan.png?alt=media&token=a4e09a38-78a9-4f8a-85c7-9fc9941194ca"
                alt="bestValuePlan"
                className="h-full"
              />
            </Reveal>
          </div>
        </div>

        <div className="lg:ml-[7rem] lg:block hidden">
          <div className="lg:w-60 w-20 lg:h-[20rem] bg-[#2E369C] skew-x-[-13deg] relative">
            <div className="font-extrabold mt-[50%] skew-x-[13deg] left-0 absolute">
              <p className="lg:text-[45px] lg:ml-[-10%] text-[#ffffff]">
                PRODUCT
              </p>
              <p className="lg:text-[45px] lg:mt-[2rem] lg:ml-[10%] text-[#e1aa5f] ">
                OFFERINGS
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slide5;
