import heroImg3 from "../../assets/imgs/heroImg_pg3.jpg";
import xampusLogo from "../../assets/imgs/xampusLogo-removebg-preview.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import Reveal from "../Reveal";

interface Slide3Props {
  isActive: boolean;
}

function Slide3() {
  // const slideRef = useRef<HTMLDivElement>(null);

  // useGSAP(() => {
  //   if (isActive) {
  //     gsap.registerPlugin(ScrollTrigger);

  //     gsap.fromTo(
  //       ".font-extrabold",
  //       { y: 100, opacity: 0 },
  //       {
  //         y: 0,
  //         opacity: 1,
  //         duration: 1,
  //         scrollTrigger: {
  //           trigger: ".font-extrabold",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );

  //     gsap.fromTo(
  //       ".xampus",
  //       { x: -100, opacity: 0 },
  //       {
  //         x: 0,
  //         opacity: 1,
  //         duration: 1.9,
  //         scrollTrigger: {
  //           trigger: ".xampus",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );
  //   }
  // }, [isActive]);

  return (
    <div className="relative overflow-hidden flex">
      <div className="absolute inset-0 bg-[#030a74] w-[100%] left-1/2 -translate-x-1/2"></div>

      <div className="w-[40%] z-20">
        <Reveal>
          <div className="text-[#e1aa5f] font-extrabold mt-12 lg:mt-[11.3%] lg:mb-[30%] mb-[50%] lg:text-[65px] lg:mt-[4rem] lg:ml-[38%] 2xl:ml-[48%] 2xl:mt-[8.5%] ml-12 flex flex-col items-center">
            <span>MONETIZE</span>
            <span>AUDIENCE</span>
            <span>THE RIGHT</span>
            <span>PRODUCT</span>
          </div>
        </Reveal>
      </div>

      <div className="w-[60%] z-40">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FheroImg_pg3.jpg?alt=media&token=52b1abb1-228f-4e47-ba9e-a16c9f67e036"
          className="h-full w-full object-cover opacity-35"
          alt="heroImg3"
        />

        <div className="text-[#CBE3F5] font-extrabold lg:mt-10 mt-12 absolute top-0 lg:left-4 left-[42%] lg:text-[65px] lg:mt-[4rem] lg:ml-[40%] flex flex-col">
          <Reveal delay={1} duration={0.8} x={75} y={0}>
            <div className="flex flex-col">
              <span>YOUR</span>
              <span>WITH</span>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
}

export default Slide3;
