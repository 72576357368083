import heroImg4 from "../../assets/imgs/heroImg_pg4.png";
import xampusLogo from "../../assets/imgs/xampusLogo-removebg-preview.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import Reveal from "../Reveal";

interface Slide4Props {
  isActive: boolean;
}

function Slide4() {
  const slideRef = useRef<HTMLDivElement>(null);

  // useGSAP(() => {
  //   if (isActive) {
  //     gsap.registerPlugin(ScrollTrigger);

  //     gsap.fromTo(
  //       ".font-extrabold",
  //       { y: 100, opacity: 0 },
  //       {
  //         y: 0,
  //         opacity: 1,
  //         duration: 1,
  //         scrollTrigger: {
  //           trigger: ".font-extrabold",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );

  //     gsap.fromTo(
  //       ".xampus",
  //       { x: -100, opacity: 0 },
  //       {
  //         x: 0,
  //         opacity: 1,
  //         duration: 1.9,
  //         scrollTrigger: {
  //           trigger: ".xampus",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );
  //   }
  // }, [isActive]);

  return (
    <div className="bg-[#030a74] lg:h-[90vh] h-[30vh] overflow-hidden relative w-full border-y-4">
      <div className="text-[#e1aa5f] font-extrabold lg:mt-[5%] flex justify-center mt-4">
        <Reveal delay={0} duration={0.5} y={75} x={0}>
          <p className="lg:text-[45px] lg:leading-[3.5rem]">
            INTEGRITY <span className="text-white">BUILDS TRUST,</span>
            <br />
            AND <span className="text-white">TRUST UNLOCKS LIMITLESS</span>{" "}
            <br />
            WEALTH
          </p>
        </Reveal>
      </div>

      <div className="lg:absolute lg:mt-[-13rem] mt-[-2rem]">
        <img
          className="font-extrabold"
          src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FheroImg_pg4.png?alt=media&token=65ba3c14-9d58-4152-a274-be4b9a78b4ef"
          alt="heroImg4"
        />
      </div>
    </div>
  );
}

export default Slide4;
