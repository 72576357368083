import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import useResponsiveFontSize from "../utils/useResponsiveFontSize";

interface Props {
  text: string;
}

const SplitText = ({ text }: Props) => {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const chars = text.split("");
      const tl = gsap.timeline();

      // Clear content and set position to relative
      textRef.current.innerHTML = "";
      textRef.current.style.position = "relative";

      chars.forEach((char, index) => {
        // Create a span for each character
        const charElement = document.createElement("span");
        charElement.textContent = char;
        charElement.style.position = "relative";
        charElement.style.display = "inline-block";

        // Apply yellow color only to "AI"
        if (char === "A" || char === "I") {
          charElement.style.color = "#CA8A04"; // Yellow color
        }

        // Append the span to the text container
        if (textRef.current) {
          textRef.current.appendChild(charElement);
        }

        // Animate the character's opacity
        tl.from(
          charElement,
          {
            opacity: 0,
            x: -20,
            duration: 0.9,
            ease: "power3.out",
            delay: 0.3,
          },
          "<"
        );
      });
    }
  }, [text]);

  return <div ref={textRef}>{text}</div>;
};

export default SplitText;
