function WhyDoWeExist() {
  return (
    <div className="w-full lg:mt-[10rem] lg:flex lg:justify-center gap-1 lg:h-[80vh] bg-aliceBlue mt-2">
      <div className="lg:h-[100%] lg:w-[50%]">
        <div>
          <h1 className="lg:text-[33px] text-[#060D75] text-[1rem] font-bold md:text-[1.6rem] mt-[5rem] pt-10 ml-8">
            "Why do we exist?"{" "}
          </h1>

          <p className="text-[#060D75] text-[16px] font mr-20 ml-10 ">
            Xampus aims to be a direct bridge connecting 300 million companies
            with 300 million creators, eliminating the need for influencer
            agencies that typically charge 20% to 30% in fees.
          </p>
        </div>
        <div>
          <h1 className="lg:text-[33px] text-[#060D75] text-[1rem] font-bold md:text-[1.6rem] mt-[2rem] ml-8">
            "How do we do that?"{" "}
          </h1>

          <p className="text-[#060D75] text-[16px] font mr-20 ml-10 ">
            Connect all your social accounts and showcase your real-time
            audience to millions. Leverage AI-powered analytics and competitive
            profiling to compare your performance with other creators. Unlock
            deep insights and stay ahead with our cutting-edge solutions!
          </p>
        </div>
      </div>

      <div className="lg:w-[30%] w-[80%] ml-[40px] mt-10 flex justify-center items-center block-vault-intro__image fade-in-up in-viewport">
        <img
          decoding="async"
          src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FiPhone15_Mockup-instaConnect.png?alt=media&token=6ddf71dc-2e80-49d3-a30c-5a954b85abe1"
          alt=""
          className="inline-block align-middle transform perspective-1 translate-z-0 shadow-transparent animate-bob"
        />
      </div>
    </div>
  );
}

export default WhyDoWeExist;
