import React from "react";
import Reveal from "./Reveal";

function Test() {
  return (
    <div className="h-screen w-screen flex  justify-center overflow-hidden">
      <Reveal>
        <p className="border p-2 rounded-2xl bg-[#000000] text-white h-16 w-40 text-center pt-4">
          Hello World
        </p>
      </Reveal>
    </div>
  );
}

export default Test;
