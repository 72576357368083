import heroImg2 from "../../assets/imgs/heroImg_pg2.jpg";
import xampusLogo from "../../assets/imgs/xampusLogo-removebg-preview.png";
import threeMobilesImg from "../../assets/imgs/threeMobiles.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import Reveal from "../Reveal";

interface Slide2Props {
  isActive: boolean;
}

function Slide2() {
  const slideRef = useRef<HTMLDivElement>(null);

  // useGSAP(() => {
  //   gsap.registerPlugin(ScrollTrigger);

  //   if (isActive) {
  //     gsap.fromTo(
  //       ".font-extrabold",
  //       { y: 100, opacity: 0 },
  //       {
  //         y: 0,
  //         opacity: 1,
  //         duration: 1,
  //         scrollTrigger: {
  //           trigger: ".font-extrabold",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );

  //     gsap.fromTo(
  //       ".xampus",
  //       { x: -100, opacity: 0 },
  //       {
  //         x: 0,
  //         opacity: 1,
  //         duration: 1.9,
  //         scrollTrigger: {
  //           trigger: ".xampus",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );
  //   }
  // }, [isActive]);

  return (
    <div className="flex flex-col items-center relative overflow-hidden border-y-4">
      {/* Blue color opacity Div */}
      <div className="absolute inset-0 bg-[#030a74] w-[100%] left-1/2 -translate-x-1/2"></div>

      {/* MAIN HERO IMG */}
      <img
        className="w-[100%] opacity-25"
        src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FheroImg_pg2.jpg?alt=media&token=f9526f31-74bb-4041-a1ef-b855112b2e05"
        alt="heroImage"
      />

      <div
        ref={slideRef}
        className="absolute text-[#cdd5ea] font-extrabold lg:w-[92%] lg:h-[57%] lg:top-[38%] z-10 top-[30%] w-[80%]"
      >
        <Reveal>
          <div>
            <p className="lg:text-[80px]">
              GET <span className="text-[#FFBD59]">11.01%</span> FOR
            </p>
            <p className="lg:text-[80px] lg:mt-[4rem]">EVERY REFERRAL</p>
          </div>
        </Reveal>
      </div>

      <div ref={slideRef} className="lg:w-[2rem] right-[4rem]">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FthreeMobiles.png?alt=media&token=f158ea87-5f3b-4bb3-980c-bd4588fd4053"
          className="bottom-0 absolute w-[80%] opacity-60 font-extrabold"
          alt="threeMobiles"
        />
      </div>
    </div>
  );
}

export default Slide2;
