import React from "react";

function PromotionalVideo() {
  return (
    <div>
      <div className="flex justify-center items-center lg:h-screen pt-10 flex-col">
        <video
          controls
          className="lg:w-full lg:max-w-4xl rounded-lg shadow-lg w-11/12"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/MarketingData%2FVideoAds%2FXampusAIPromo.mp4?alt=media&token=21286a92-e6ac-4b71-87f9-cb8a78c7f3a5"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <h3 className="text-center">
          Part One: AI-Powered Article Summarization
        </h3>
      </div>
    </div>
  );
}

export default PromotionalVideo;
