import { useState } from "react";

interface Props {
  text: string;
  maxLength?: number;
}

const ExpandableParagraph = ({ text, maxLength = 110 }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedText =
    text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

  return (
    <div>
      <span className="text-[12px] leading-[23px]">
        {isExpanded ? text : truncatedText}
      </span>
      <span>
        {text.length > maxLength && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-500 hover:underline focus:outline-none text-[12px] leading-[23px]"
          >
            {isExpanded ? "see less" : "see more"}
          </button>
        )}
      </span>
    </div>
  );
};

export default ExpandableParagraph;
