// import heroImg1 from "../../assets/imgs/heroImg_pg1.jpg";
// import xampusLogo from "../../assets/imgs/xampusLogo-removebg-preview.png";
// import { useGSAP } from "@gsap/react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import Reveal from "../Reveal";

interface Slide1Props {
  isActive: boolean;
}

function Slide1() {
  const slideRef = useRef<HTMLDivElement>(null);

  // useGSAP(() => {
  //   gsap.registerPlugin(ScrollTrigger);

  //   if (isActive) {
  //     gsap.fromTo(
  //       ".font-extrabold",
  //       { y: 100, opacity: 0 },
  //       {
  //         y: 0,
  //         opacity: 1,
  //         duration: 1,
  //         scrollTrigger: {
  //           trigger: ".font-extrabold",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );
  //   }

  //   gsap.fromTo(
  //     ".xampus",
  //     { x: -100, opacity: 0 },
  //     {
  //       x: 0,
  //       opacity: 1,
  //       duration: 1.9,
  //       scrollTrigger: {
  //         trigger: ".xampus",
  //         start: "top 80%",
  //         end: "top 50%",
  //         toggleActions: "play none none reverse",
  //       },
  //     }
  //   );
  // });

  return (
    <div className="flex flex-col justify-center items-center relative bg-[#000000] h-[100%] overflow-hidden">
      {/* Blue color opacity Div */}
      <div className="absolute inset-0 bg-[#030a74] w-[100%] lg:h-[100%] left-1/2 -translate-x-1/2"></div>

      {/* MAIN HERO IMG */}
      <Reveal delay={0} duration={0.5} y={-75} x={0}>
        <img
          className="w-[100%] h-auto lg:-mt-[7%] opacity-50"
          src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FheroImg_pg1.jpg?alt=media&token=71c5173f-288f-44bc-bcfa-8ba7b1a6ace4"
          alt="heroImage"
        />
      </Reveal>
      <div className="absolute w-[83%] lg:h-[62%] h-[67%] top-[12%] z-10">
        <div className="font-bold lg:text-[2rem] flex items-center ">
          <img
            className="lg:w-[53px] w-[30px] opacity-45"
            src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FxampusLogo-removebg-preview.png?alt=media&token=1118cedc-63ae-418c-9526-49d09715504b"
            alt="xampusLogo"
          />
          <Reveal delay={1} duration={0.8}>
            <div>
              <span className="text-[#CBE3F5] xampus">Xampus</span>
              <span className="text-[#FFBD59] xampus">AI</span>
            </div>
          </Reveal>
        </div>

        <div className="absolute text-[#e1aa5f] font-extrabold bottom-0 lg:text-[80px] text-[1.4rem] flex flex-col">
          <Reveal delay={0.5} duration={0.5} y={75} x={0}>
            <div className="flex flex-col">
              <span>INTRODUCING</span>
              <span>XAMPUS CREATOR</span>
            </div>
          </Reveal>
        </div>
      </div>

      {/* BLUE COLOR DIV */}
      <div className="lg:h-[27%] h-[20%] bg-[#030A74] z-10 w-[100%] absolute lg:bottom-0 bottom-0">
        <p className="lg:text-[80px] text-[1.5rem] ml-[8.2%] lg:mt-[3rem] m-0 absolute text-[#cdd5ea] font-extrabold top-0 w-[100%] lg:pt-0 pt-2">
          <Reveal delay={0.9} duration={0.6} y={75} x={0}>
            <span>REFERRAL PROGRAM 🚀</span>
          </Reveal>
        </p>
      </div>
    </div>
  );
}

export default Slide1;
