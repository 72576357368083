import { useState, useEffect } from "react";

function useResponsiveFontSize(baseWidth = 1440, baseFontSize = 36) {
  const [fontSize, setFontSize] = useState(baseFontSize);
  useEffect(() => {
    const updateFontSize = () => {
      const newFontSize = (window.innerWidth / baseWidth) * baseFontSize;
      setFontSize(newFontSize);
    };
    window.addEventListener("resize", updateFontSize);
    updateFontSize(); // Set initial font size
    return () => window.removeEventListener("resize", updateFontSize);
  }, [baseWidth, baseFontSize]);
  return fontSize;
}
export default useResponsiveFontSize;
