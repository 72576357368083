function ChildSafetyStandards() {
  return (
    <div className="p-[1rem] lg:p-[1.5rem]">
      <h1 className="text-[1.2rem] font-bold lg:text-center lg:text-[1.5rem]">
        Xampus Child Safety Standards Policy{" "}
      </h1>

      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">1. Purpose</h2>

        <p className="text-[13px]">
          Xampus is committed to providing a safe, secure, and respectful
          platform for all users, including minors. This Child Safety Standards
          Policy outlines our approach to protecting children and ensuring their
          safety while using our services.
        </p>
      </div>

      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          2. Age Verification
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            Xampus requires users to be at least <strong>13 years</strong> old
            to create an account.
          </li>
          <li>
            For users below the age of 18, parental consent may be required for
            certain services or features.
          </li>
          <li>
            We employ age verification mechanisms to ensure that children are
            not exposed to content or interactions that are not appropriate for
            their age.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          3. Parental Controls
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            Xampus offers parents or guardians the ability to manage and monitor
            their child’s account, including setting permissions and
            notifications.
          </li>
          <li>
            Parents can restrict or control the content their child has access
            to, including posts, messages, and articles.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          4. Content Moderation
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            All user-generated content on Xampus is subject to moderation.
            Content deemed inappropriate for minors will be flagged, reviewed,
            and removed.
          </li>
          <li>
            Users can report any harmful content or interactions directly
            through the app for review.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          5. Privacy and Data Protection
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            Xampus strictly adheres to privacy laws such as COPPA (Children's
            Online Privacy Protection Act) to protect the personal information
            of minors.
          </li>
          <li>
            We do not collect sensitive data from children without obtaining
            verified parental consent.
          </li>
          <li>
            The collection and usage of personal data for minors will be limited
            to the bare minimum necessary for the functionality of the app.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          6. Communication Standards
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            Direct communication between minors and other users is restricted to
            prevent unsolicited or inappropriate interactions.
          </li>
          <li>
            Private messaging features may be disabled for users under the age
            of 18, depending on parental preferences.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          7. Online Behavior and Interaction
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            We encourage positive and respectful behavior across the platform.
            Harassment, bullying, and any form of exploitation will not be
            tolerated.
          </li>
          <li>
            We maintain an in-app reporting system for incidents of
            inappropriate behavior or interactions that may affect children.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          8. User Education
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            Xampus provides educational resources to minors, parents, and
            guardians about online safety, including how to report concerns,
            understand privacy settings, and practice healthy online habits.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          9. Regular Audits and Updates
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            Xampus will perform regular audits of our safety measures and
            policies to ensure compliance with changing laws and best practices.
          </li>
          <li>
            Our Child Safety Standards Policy will be updated periodically to
            reflect any new developments in online safety.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          10. Enforcement
        </h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            Any violations of the Child Safety Standards Policy may result in
            account suspension, permanent ban, or legal action if necessary.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">11. Contact</h2>

        <ul className="list-disc list-inside text-[13px] space-y-[10px] pt-[15px]">
          <li>
            For any concerns or reports regarding child safety, please contact
            our support team at{" "}
            <a className="text-[#243c5a]" href="developer@xampus.in.">
              developer@xampus.in.
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ChildSafetyStandards;
