import SwapStatus from "../components/SwapComponent";
import JoinOurCommunity from "./JoinOurCommunity";
import PromotionalVideo from "./PromotionalVideo";
import playstoreQR from "../assets/imgs/playStoreQR.png";
import appstoreQR from "../assets/imgs/appStoreQR.png";

function OtherFeatues() {
  return (
    <div
      className="lg:mt-32 mt-20 w-full lg:pb-10"
      style={{
        background:
          "linear-gradient(180deg, #FFFFFF 0.6%, #E0E6FF 44.6%, #060D75 92.6%)",
      }}
    >
      <div className="w-full mt-10 lg:flex justify-center items-center flex-col gap-10 lg:px-32">
        <h1 className="text-3xl font-bold text-center text-[#060D75] ">
          Are there any other features available?
        </h1>
      </div>
      {/* SWIPER */}

      <div className="mt-20 lg:flex">
        <div className="lg:w-[70%]  lg:mr-[-130px] mx-4 bg-bl">
          <SwapStatus />
        </div>

        {/* checkout xampus app section */}

        <div className="flex justify-center items-center lg:w-[40%] lg:mt-[-100px] lg:mr-20 mx-4 mt-[60px]">
          <div className="flex flex-col justify-center items-center gap-3">
            <h1 className="text-2xl font-bold text-center">
              Checkout our Xampus App
            </h1>
            <h6 className="text-center">
              Connect, collaborate, and cultivate your career with like-minded
              professionals across diverse industries.
            </h6>
            <div className="flex  justify-center items-center lg:gap-14 gap-10">
              {/* FOR ANDROID */}
              <div className="flex justify-center items-center flex-col gap-2">
                <img
                  className="lg:w-[100px] lg:h-[100px] rounded-lg w-[130px] h-[130px]"
                  src={playstoreQR}
                  alt=""
                />
                <button
                  className="flex justify-center items-center px-2 py-1 rounded-lg bg-gradient-to-r from-[#0D8CEB] via-[#3179E8] via-[#436AF4] via-[#584FD8] to-[#6A3FC3] text-white px-4 py-2 rounded-lg"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.xampus.ai.app",
                      "_blank"
                    )
                  }
                >
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2Fgoogle-play%201.png?alt=media&token=f93c3449-869b-495b-9e41-b276eaf91bfc"
                    }
                    alt="Play store logo"
                    className="mr-2 h-[25px] w-[25px]"
                  />
                  <span className="text-[15px]">Play Store</span>
                </button>
              </div>
              {/* FOR IOS */}
              <div className="flex justify-center items-center flex-col gap-2">
                <img
                  className="lg:w-[100px] lg:h-[100px] rounded-lg w-[130px] h-[130px]"
                  src={appstoreQR}
                  alt=""
                />
                <button
                  className="flex justify-center items-center bg-[#f0f0f0]  px-2 py-1 rounded-lg bg-gradient-to-r from-[#0D8CEB] via-[#3179E8] via-[#436AF4] via-[#584FD8] to-[#6A3FC3] text-white px-4 py-2 rounded-lg"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/app/xampus-ai/id6738393522",
                      "_blank"
                    )
                  }
                >
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2Fappstore.webp?alt=media&token=310b78ba-15bc-46a9-a2ba-dee8cba6eb49"
                    }
                    alt="Apple store logo"
                    className="mr-2 h-[25px] w-[25px]"
                  />
                  <span className="text-[15px]">App Store</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PromotionalVideo />

      <JoinOurCommunity />
    </div>
  );
}

export default OtherFeatues;
