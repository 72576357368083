import Navbar from "../components/Navbar";
import ProfileCard from "../components/ProfileCard";
// Images
import akshayImg from "../assets/imgs/akshay.webp";
import sonaliImg from "../assets/imgs/sonali.jpg";
import nupurImg from "../assets/imgs/nupurImg.jpeg";
import arthavImg from "../assets/imgs/arthav.jpeg";
import ayushvsImg from "../assets/imgs/ayushvs.jpeg";
import rishikeshImg from "../assets/imgs/rishikesh.jpg";
import sandhyaImg from "../assets/imgs/sandhyaImg.jpg";
import rushikeshImg from "../assets/imgs/rushikeshNew.jpg";
import storyImg from "../assets/imgs/storyImgNew.svg";
import dreamImg from "../assets/imgs/dreamImg.svg";
import growthImg from "../assets/imgs/growthImg.svg";
import rasika from "../assets/imgs/rasikaImg.jpg";

import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import useResponsiveFontSize from "../utils/useResponsiveFontSize";
import RedesignedFooter from "../components/RedesignedFooter";

const RedesignedAboutUs = () => {
  gsap.registerPlugin(ScrollTrigger);

  const textRef1 = useRef<HTMLDivElement>(null);
  const textRef2 = useRef<HTMLDivElement>(null);
  const textRef3 = useRef<HTMLDivElement>(null);
  const textRef4 = useRef<HTMLDivElement>(null);
  const iconRef1 = useRef<HTMLDivElement>(null);
  const iconRef2 = useRef<HTMLDivElement>(null);
  const triggerIconRef = useRef<HTMLDivElement>(null);

  const storyRef1 = useRef<HTMLDivElement>(null);
  const storyRef2 = useRef<HTMLDivElement>(null);
  const dreamRef1 = useRef<HTMLDivElement>(null);
  const dreamRef2 = useRef<HTMLDivElement>(null);
  const growthRef1 = useRef<HTMLDivElement>(null);
  const growthRef2 = useRef<HTMLDivElement>(null);
  const triggerStoryRef = useRef<HTMLDivElement>(null);
  const triggerDreamRef = useRef<HTMLDivElement>(null);
  const triggerGrowthRef = useRef<HTMLDivElement>(null);

  const triggerCardRef = useRef<HTMLDivElement>(null);
  const profileCardRef1 = useRef<HTMLDivElement>(null);
  const profileLeftCardRef2 = useRef<HTMLDivElement>(null);
  const profileRightCardRef2 = useRef<HTMLDivElement>(null);
  const profileCardRef3 = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    gsap.from(iconRef1.current, {
      x: -200,
      duration: 1.8,
      opacity: 0,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
        start: "top 50%",
      },
    });
    gsap.from(iconRef2.current, {
      x: 200,
      duration: 1.8,
      opacity: 0,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
        start: "top 50%",
      },
    });

    //TEXT REF ANIMATION
    gsap.from(textRef1.current, {
      y: -200,
      duration: 2,
      opacity: 0,
      delay: 0.6,
    });
    gsap.from(textRef2.current, {
      y: 200,
      duration: 2,
      opacity: 0,
      delay: 0.6,
    });
    gsap.from(textRef3.current, {
      y: -160,
      duration: 1.8,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
      },
    });
    // Story animations ref
    gsap.from(storyRef1.current, {
      x: -450,
      duration: 1.3,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: triggerStoryRef.current,
        scroller: "body",
        start: "top 100%",
      },
    });

    gsap.from(storyRef2.current, {
      x: 450,
      duration: 1.3,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: triggerStoryRef.current,
        scroller: "body",
        start: "top 100%",
      },
    });
    // Dream and Growth Animation

    gsap.from(dreamRef1.current, {
      x: 450,
      duration: 1.3,
      opacity: 0,
      scrollTrigger: {
        trigger: triggerDreamRef.current,
        scroller: "body",
        start: "top 200%",
      },
    });

    gsap.from(dreamRef2.current, {
      x: -450,
      duration: 1.3,
      opacity: 0,
      scrollTrigger: {
        trigger: triggerDreamRef.current,
        scroller: "body",
        start: "top 200%",
      },
    });

    // GROWTH ANIMATION
    gsap.from(growthRef1.current, {
      x: -450,
      duration: 1.3,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: triggerStoryRef.current,
        scroller: "body",
        start: "top 100%",
      },
    });

    gsap.from(growthRef2.current, {
      x: 450,
      duration: 1.3,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: triggerStoryRef.current,
        scroller: "body",
        start: "top 100%",
      },
    });

    gsap.from(profileCardRef1.current, {
      y: -350,
      duration: 1.3,
      opacity: 0,
      delay: 0.3,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 200%",
      },
    });
    gsap.from(profileCardRef3.current, {
      y: 350,
      duration: 1.3,
      opacity: 0,
      delay: 1,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 20%",
      },
    });
    gsap.from(textRef4.current, {
      x: -350,
      duration: 1.3,
      opacity: 0,
      delay: 0.3,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 200%",
      },
    });
    gsap.from(profileLeftCardRef2.current, {
      x: -350,
      duration: 1.8,
      opacity: 0,
      delay: 0.8,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 200%",
      },
    });
    gsap.from(profileRightCardRef2.current, {
      x: 350,
      duration: 1.8,
      opacity: 0,
      delay: 0.8,
      scrollTrigger: {
        trigger: triggerCardRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
  });

  return (
    <>
      <Helmet>
        <title>About Us: A Confident Look at Our Growth!</title>
        <meta name="keywords" content="xampus, about us, team, career" />
      </Helmet>
      <Navbar styles="bg-white" />
      {/* <div
        className="relative min-h-[45rem] md:min-h-screen flex flex-col items-center bg-cover bg-center bg-no-repeat px-4 md:px-0 bg-[#1C2383]"
        // style={{ backgroundImage: `url(${aboutImg})` }}
      >
        <div
          className="relative z-10 text-center text-white mt-10 md:mt-0 mb-6 md:mb-12"
          ref={textRef1}
        >
          <h1 className="text-5xl lg:text-6xl font-bold lg:mt-[7rem]">
            About Us
          </h1>
        </div>
        <div
          className="relative z-10 w-full md:w-[80%] space-y-6 md:space-y-10"
          ref={textRef2}
        >
          <p className="text-white text-center text-left md:text-center text-base md:text-lg lg:text-xl tracking-wider">
            Xampus is the new age professional network designed specifically for
            India’s dynamic and diverse workforce. We aim to revolutionize the
            way professionals connect, collaborate, and grow their careers in
            the digital age.
          </p>

          <p className="text-white text-center text-left md:text-center text-base md:text-lg lg:text-xl tracking-wider">
            India’s premier AI-enabled professional networking platform. Here,
            you can connect, collaborate, and cultivate your career with
            like-minded professionals from diverse industries. Whether you’re
            looking to unlock new opportunities, showcase your expertise, or
            elevate your professional journey, Xampus is the place to be. Join
            us today and take the next step towards realizing your career
            aspirations. Your future of professional growth and success starts
            here.
          </p>
        </div>
      </div> */}

      <div className="">
        {/* OUR STORY */}
        <div
          ref={triggerStoryRef}
          className="lg:mx-[10rem] lg:mt-[5rem] mx-6 mt-6"
        >
          <div className="lg:pl-[2rem] " ref={storyRef2}>
            <h2 className="text-[2rem] md:text-[2.5rem] font-bold text-center text-[#060D75]">
              Our Story
            </h2>
            <p className="justify-center items-center text-[#0A1E67] text-[1rem]">
              Founded in May 2024, the creator economy is booming, with a global
              market value of $191.55 billion, growing at a staggering CAGR of
              22.5%. By 2030, it is expected to reach an astonishing $528.39
              billion. Yet, despite this rapid growth, a significant gap
              remains—one that prevents influencers and creators from connecting
              directly with sponsors, and vice versa. Brands struggle to find
              the right creators for collaborations, while creators often miss
              out on lucrative opportunities due to the lack of a streamlined
              connection. Seeing this challenge firsthand, we decided to take
              action. <br /> Our platform was born with a simple yet powerful
              mission: to act as a direct bridge between influencers and
              sponsors, eliminating barriers and creating seamless
              collaborations. But we didn’t stop there. We envisioned a
              comprehensive ecosystem where creators, professionals, and
              businesses could thrive. That’s why our platform also includes
              additional features like global job postings, an interactive
              community, and AskAI—a feature specifically optimized to deliver
              real-time job opportunities, insights, and articles across various
              domains like technology, science, business, and more. With
              innovation at our core, we are redefining the creator economy by
              providing a space where talent meets opportunity, where ideas
              transform into impactful collaborations, and where creators gain
              the power to scale their influence beyond limits.
            </p>
          </div>

          {/* <div className="flex justify-center items-center" ref={storyRef1}>
            <img
              src={storyImg}
              loading="lazy"
              alt="vector"
              className="h-[12rem] md:h-[14rem] lg:h-[18rem]"
            />
          </div> */}
        </div>

        {/* STARTED WITH A DREAM */}
        {/* <div
          className="min-h-[25rem]  grid grid-cols-1 gap-[3rem] md:grid-cols-2 justify-center items-center  gap-x-8 px-1 md:px-4  text-customBlue"
          ref={triggerDreamRef}
        >
          <div
            className="flex justify-center items-center lg:hidden"
            ref={dreamRef1}
          >
            <img
              src={dreamImg}
              loading="lazy"
              alt="vector"
              className="h-[12rem] md:h-[14rem] lg:h-[18rem]"
            />
          </div>

          <div
            className="w-[100%]  text-start pl-[2rem] lg:pl-[7rem]"
            ref={dreamRef2}
          >
            <h2 className="text-[2rem] md:text-[2.5rem] font-bold mb-6 ">
              We started with a dream
            </h2>
            <p className="text-base md:text-lg lg:text-xl tracking-wider">
              Xampus's dream is to revolutionize professional networking for
              India's diverse and dynamic workforce by creating a platform that
              facilitates meaningful connections, fosters collaboration, and
              supports career growth in the digital era. They aim to empower
              professionals to advance their careers and achieve their
              professional goals through a tailored and innovative networking
              experience.
            </p>
          </div>

          <div
            className="flex justify-center items-center hidden lg:flex"
            ref={dreamRef1}
          >
            <img
              src={dreamImg}
              loading="lazy"
              alt="vector"
              className="h-[12rem] md:h-[14rem] lg:h-[18rem]"
            />
          </div>
        </div> */}

        {/* GROWTH */}
        {/* <div
          className="min-h-[25rem] grid grid-cols-1 gap-[3rem] md:grid-cols-2 justify-center items-center  gap-x-8 px-1 md:px-4 text-customBlue"
          ref={triggerGrowthRef}
        >
          <div className="flex justify-center items-center" ref={growthRef1}>
            <img
              src={growthImg}
              loading="lazy"
              alt="vector"
              className="h-[12rem] md:h-[14rem] lg:h-[18rem]"
            />
          </div>

          <div className="pl-[2rem] w-[90%]  text-start" ref={growthRef2}>
            <h2 className="text-[2rem] md:text-[2.5rem] font-bold ">Growth</h2>
            <p className="text-base md:text-lg lg:text-xl tracking-wider">
              Since our inception, we have grown into a vibrant community of
              over 10 professionals from various industries and backgrounds. Our
              team of 10+ dedicated individuals works tirelessly to improve and
              expand our services.
            </p>
          </div>
        </div> */}
      </div>

      {/* Previous classname =  min-h-screen relative text-center  overflow-hidden*/}
      <div
        className="h-[15rem] relative text-center  overflow-hidden"
        ref={triggerCardRef}
        style={{
          background:
            "linear-gradient(180deg, #FFFFFF 0.6%, #E0E6FF 44.6%, #060D75 92.6%)",
        }}
      >
        {/* <div ref={textRef4}>
          <h1 className="text-[2rem] md:text-[2.5rem] font-bold mb-10 text-[#060D75]">
            Meet The Team
          </h1>
        </div> */}

        {/****************** Profile Cards  ********************/}

        {/* <div
          className="flex flex-wrap justify-center gap-8 px-4"
          ref={profileCardRef1}
        >
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={akshayImg}
              name="Akshay Khandade"
              designation="Founder, CEO"
              email="akshay@xampus.in"
            />
          </div>
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={sonaliImg}
              name="Sonali Khandade"
              designation="Co-founder"
              email="sonali.k@xampus.in"
            />
          </div>
        </div> */}

        {/* <div className="flex flex-wrap justify-center gap-8 px-4 mt-8">
          <div className="flex gap-8" ref={profileLeftCardRef2}>
            <div className="flex flex-col items-center">
              <ProfileCard
                profileImg={ayushvsImg}
                name="Ayush Singh"
                designation="Frontend Developer"
                email="ayush.s@xampus.in"
              />
            </div>
            <div className="flex flex-col items-center">
              <ProfileCard
                profileImg={sandhyaImg}
                name="Sandhya Mahajan"
                designation="Content Creator"
                email="sandhya.m@xampus.in"
              />
            </div>
          </div>

          <div className="flex gap-8" ref={profileRightCardRef2}>
            <div className="flex flex-col items-center">
              <ProfileCard
                profileImg={rasika}
                name="Rasika Bagewadi"
                designation="QA Tester"
                email="rasika.b@xampus.in"
              />
            </div>

            <div className="flex flex-col items-center">
              <ProfileCard
                profileImg={arthavImg}
                name="Atharva Deokar"
                designation="UI UX Designer"
                email="atharva.d@xampus.in"
              />
            </div>
          </div>
        </div> */}

        {/* Last Row with 3 Cards */}
        {/* <div
          className="flex flex-wrap justify-center gap-8 px-4 mt-8"
          ref={profileCardRef3}
        >
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={nupurImg}
              name="Nupur Pawar"
              designation="UI UX Designer"
            />
          </div>
          <div className="flex flex-col items-center">
            <ProfileCard
              profileImg={rushikeshImg}
              name="Rushikesh Darode"
              designation="Mobile Developer"
            />
          </div>
        </div> */}
      </div>

      <RedesignedFooter />
    </>
  );
};

export default RedesignedAboutUs;
