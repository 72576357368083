function JoinOurCommunity() {
  return (
    <div className="w-full lg:pt-[1rem] lg:flex lg:justify-center gap-1 lg:h-[90vh] bg-aliceBlue mt-2">
      <div className="lg:h-[100%] lg:w-[50%]">
        <div>
          <h1 className="lg:text-[33px] text-[#060D75] text-[1rem] font-bold md:text-[1.6rem] mt-[5rem] pt-10 ml-8 pr-10">
            How much AI is too much AI, and how will Xampus utilize it?
          </h1>

          <p className="text-[#060D75] text-[16px] font mr-20 ml-10 ">
            Currently, we can provide worldwide job listings and articles with a
            single command using AI. However, our vision has expanded. Now, you
            can ask for creators from any corner of the world, along with their
            audience size, availability, and charges. <br /> AI will quickly
            generate a list of available creators and influencers who can help
            grow your product or service in your target market. For creators,
            leverage AI-powered analytics and competitive profiling to compare
            your performance with other creators.
          </p>
        </div>
        <div>
          <h1 className="lg:text-[33px] text-[#060D75] text-[1rem] font-bold md:text-[1.6rem] mt-[2rem] ml-8">
            Are we growing?
          </h1>

          <p className="text-[#060D75] text-[16px] font mr-20 ml-10 ">
            Yes, we are! Join the Xampus community of 23,000 people on LinkedIn
            and stay updated with our latest news.
          </p>
        </div>
      </div>

      <div className="lg:w-[30%] w-[80%] ml-[40px] mt-10 flex justify-center items-center pb-10">
        <img
          decoding="async"
          src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FlinkedinXampusPage.png?alt=media&token=bdac4d80-c074-4a3d-b852-df423f436196"
          alt=""
          className="inline-block align-middle"
        />
      </div>
    </div>
  );
}

export default JoinOurCommunity;
