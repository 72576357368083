import React, { useRef } from "react";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper/modules";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";

const SwapStatus = () => {
  const swiperRef = useRef<SwiperCore | null>(null);
  SwiperCore.use([Autoplay]);

  const scrollLeft = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const scrollRight = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div>
      {/* Image Slider */}
      <Swiper
        onSwiper={(swiper: SwiperCore) => (swiperRef.current = swiper)}
        className="w-[100%] h-[25%]"
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FmockupForIncognoSpace.png?alt=media&token=b2e222f3-a12c-4448-b19c-213fbfcfa646"
              alt="Ask anything without revealing your identity"
              className="lg:w-[25%] w-[35%] h-auto"
            />
            <div className="text-center mt-4">
              <h1 className="text-lg font-bold text-center text-[#060D75]">
                "Ask anything without revealing your identity"
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FxampusProfileIphoneMockup.png?alt=media&token=aee28b5e-507b-4e5b-8c2e-ce36ea35af7b"
              alt="Create a profile that reflects your persona"
              className="lg:w-[25%] w-[35%] h-auto"
            />
            <div className="text-center mt-4">
              <h1 className="text-lg font-bold text-center  text-[#060D75]">
                "Create a profile that reflects your persona”
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FbottomRightNew.png?alt=media&token=4b75407b-f487-4522-bc62-7d77b6daa0e8"
              alt="Connect & Sync: Where Your Social World Aligns"
              className="lg:w-[25%] w-[35%] h-auto"
            />
            <div className="text-center mt-4">
              <h1 className="text-lg font-bold text-center  text-[#060D75]">
                "Connect & Sync: Where Your Social World Aligns”
              </h1>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FdreamJobiphoneMockup.png?alt=media&token=040ef90a-ed2a-4b3e-9b1f-e874fea4a9b0"
              alt="Find Your Dream Job, Faster and Easier."
              className="lg:w-[25%] w-[35%] h-auto"
            />
            <div className="text-center mt-4">
              <h1 className="text-lg font-bold text-center text-[#060D75]">
                "Find Your Dream Job, Faster and Easier."
              </h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="flex flex-col justify-center items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FAskAIpage.png?alt=media&token=6cc01c38-fa13-499d-bb14-6fd2470b133d"
              alt="Ask Xampus AI for articles, jobs, and more"
              className="lg:w-[25%] w-[35%] h-auto"
            />
            <div className="text-center mt-4">
              <h1 className="text-lg font-bold text-center  text-[#060D75]">
                "Ask Xampus AI for articles, jobs, and more"
              </h1>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="flex justify-center gap-4 mt-4">
        <button
          onClick={scrollLeft}
          className="px-4 py-2 bg-gray-700 text-white rounded-xl w-[100px]"
        >
          ← Left
        </button>
        <button
          onClick={scrollRight}
          className="px-4 py-2 bg-gray-700 text-white rounded-xl w-[100px]"
        >
          Right →
        </button>
      </div>
    </div>
  );
};

export default SwapStatus;
