import xampusLogo from "../../assets/imgs/xampusLogo.png";
import heroImg6 from "../../assets/imgs/heroImg_pg6.jpg";
import planPricing from "../../assets/imgs/planPricing.png";
import referralQrCode from "../../assets/imgs/referralQR.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import Reveal from "../Reveal";

interface Slide6Props {
  isActive: boolean;
}

function Slide6() {
  const slideRef = useRef<HTMLDivElement>(null);

  // useGSAP(() => {
  //   if (isActive) {
  //     gsap.registerPlugin(ScrollTrigger);

  //     gsap.fromTo(
  //       ".font-semibold",
  //       { y: 100, opacity: 0 },
  //       {
  //         y: 0,
  //         opacity: 1,
  //         duration: 1,
  //         scrollTrigger: {
  //           trigger: ".font-semibold",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );

  //     gsap.fromTo(
  //       ".xampus",
  //       { x: -100, opacity: 0 },
  //       {
  //         x: 0,
  //         opacity: 1,
  //         duration: 1.9,
  //         scrollTrigger: {
  //           trigger: ".xampus",
  //           start: "top 80%",
  //           end: "top 50%",
  //           toggleActions: "play none none reverse",
  //         },
  //       }
  //     );
  //   }
  // }, [isActive]);

  return (
    <div className="lg:h-[100vh] h-[60vh] relative w-full overflow-x-hidden">
      {/* Blue Background Cover */}
      <div className="absolute inset-0 bg-[#2e369c] lg:w-[73%]"></div>
      {/* LOGO */}
      <div className="absolute lg:block hidden w-[92%] top-[13%]">
        <div className="font-bold text-[2rem] flex items-center ml-20 gap-4">
          <img
            className="w-[45px] z-30 rounded-2xl"
            src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FxampusLogo.png?alt=media&token=846e2a0b-2147-4e79-a95b-43841de96824"
            alt="xampusLogo"
          />
          <span className="text-[#CBE3F5] xampus">Xampus</span>
          <span className="text-[#FFBD59] xampus">AI</span>
        </div>
      </div>

      {/* Image Container */}
      <div className="lg:w-[73%] w-[100%] h-full relative overflow-hidden">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FheroImg_pg6.jpg?alt=media&token=b4ee1ac3-f765-4645-8946-8878fe9e2e2f"
          alt="heroImg6"
          className="w-full h-full object-cover opacity-10 lg:object-left"
        />

        <div className="h-full w-full absolute overflow-hidden z-30 lg:top-[40%] top-[10%] flex flex-col items-center gap-[3rem] lg:p-0 px-4">
          <Reveal delay={0.9} duration={0.6} y={75} x={0}>
            <p className="text-white lg:text-[2rem] font-semibold lg:leading-[2.4rem]">
              Only creators with over
              <span className="text-[#FFBD59]"> 100k followers </span> or{" "}
              <br className="lg:block hidden" />
              <span className="text-[#FFBD59]">subscribers</span> are{" "}
              <span className="text-[#FFBD59]">eligible</span> for this program.
            </p>
          </Reveal>

          <Reveal delay={0.9} duration={0.6} y={75} x={0}>
            <p className="text-white lg:text-[1.5rem] lg:ml-[4rem] font-semibold">
              You will earn a{" "}
              <span className="text-[#FFBD59]">recurring commission</span> on
              each purchase.
            </p>
          </Reveal>

          {/* <Reveal delay={1} duration={1} y={10} x={0}>
            </Reveal> */}
          <img
            className="lg:w-[90%] rounded-2xl left-1/2"
            src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FplanPricing.png?alt=media&token=4bbcdd7b-06c6-4f4a-b1f1-9f0d882404a4"
            alt=""
          />

          <Reveal delay={0.9} duration={0.6} y={75} x={0}>
            <p className="text-white lg:text-[1.5rem] lg:ml-[4rem] font-semibold mt-[-2rem] text-center">
              Visit{" "}
              <a
                href="https://xampus.in/"
                className="underline underline-offset-1 text-[#FFBD59]"
              >
                <span>xampus.in</span>
              </a>{" "}
              for more information
            </p>
          </Reveal>
        </div>
      </div>

      {/* Empty Right Section */}

      <div className="lg:w-[27%] lg:h-full lg:absolute lg:right-0 lg:top-0 flex flex-col items-center xampus lg:bg-white lg:justify-center justify-start">
        <Reveal>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/xampus-ai.appspot.com/o/WebsiteAssets%2FAyush_Assets%2FreferralQR.png?alt=media&token=378793e4-9012-4045-ad2e-4955efe7fe6e"
            alt=""
            className="lg:w-[15rem] lg:h-[15rem] w-[13rem] lg:mt-[14rem] mt-8"
          />
        </Reveal>

        <Reveal delay={0.9} duration={0.6} y={75} x={0}>
          <div className="flex flex-col items-center pb-8">
            <p className="font-semibold lg:mt-[2rem] mt-[1rem]">
              Scan the code
            </p>
            <p className="font-semibold text-center lg:mt-[4rem] mt-[2rem] px-[2rem]">
              You can contact via Whatsapp <br /> at{" "}
              <span className="text-[#2e369c]">7020999022</span> or email{" "}
              <span className="text-[#2e369c]">akshay@xampus.in</span> to join
              the <br /> program.
            </p>
          </div>
        </Reveal>
      </div>
    </div>
  );
}

export default Slide6;
